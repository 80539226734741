.createuser {
    height: 100vh;
    width: 100%;
}

.createuser__container {
    display: flex;
    flex-direction: column;
    background-color: #dcdcdc;
    padding: 30px;
    border-radius: 5px;
}

.createuser__btn {
    padding: 18px;
    font-size: 16px;
    margin-top: 10px;
    border: none;
    color: white;
    background-color: black;
}