.reset {
    height: 100vh;
    width: 100%;
    padding-top: 5vmin;
    border-radius: 5px;
}

.reset__container {
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: #dcdcdc;
    padding: 30px;
}

.reset__textBox {
    padding: 8px;
    font-size: 16px;
    margin-bottom: 10px;
    max-width: 300px;
}

.reset__btn {
    padding: 10px;
    font-size: 18px;
    margin-bottom: 10px;
}

.reset div {
    margin-top: 7px;
}