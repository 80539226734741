.register {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.register__container {
    display: flex;
    flex-direction: column;
    background-color: #cccccc;
    padding: 30px;
    border-radius: 5px;
}

.register__btn {
    padding: 10px;
    font-size: 18px;
    margin-bottom: 10px;
    border: none;
    color: white;
    background-color: black;
}

.register__google {
    background-color: #4285f4;
}

.register div {
    margin-top: 7px;
}