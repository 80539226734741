/** MAPBOX DISPLAY **/

#mapbox {
    height: calc(100vh);
    display: block;
    flex-direction: row;
}

/** SIDEBAR DISPLAY **/

* {
    /* Calculation */
    --diff: calc(var(--max-size) - var(--min-size));
    --responsive: calc((var(--min-size) * 1px) + var(--diff) * ((100vw - 150px) / (1200 - 150)));
    /* Ranges from 421px to 1199px */
}

.sidebar {
    transition: transform 1s;
    z-index: 1;
    width: 48vmin;
}

.flex-center {
    position: absolute;
    display: flex;
    margin-left: 2.5px;
}

.left.collapsed {
    /* opposite of width minus a slice */
    transform: translateX(calc(-45vmin));
}

.sidebar-content {
    position: absolute;
    width: 95%;
    height: 99vh;
    top: .5vh;
    color: #000000;
    overflow-y: auto;
}

.rounded-rect {
    background: #ffffff75;
    border-radius: 8px;
    box-shadow: 0 0 50px -25px black;
    align-items: center;
}

.align-toggle-center {
    align-items: center;
    position: absolute;
    margin-left: 5px;
}

.sidebar-content-card-large {
    align-items: start;
    position: relative;
    background: #ffffff50;
    border-radius: 8px;
    width: 100%;
    font-family: Verdana, sans-serif;
    --max-size: 9;
    --min-size: 8;
    font-size: var(--responsive);
    color: #000000;
}

.sidebar-top-data {
    position: relative;
    font-family: Verdana, sans-serif;
    background: #ffffff50;
    border-radius: 8px;
    --max-size: 9;
    --min-size: 7;
    font-size: var(--responsive);
    color: #000000;
}

.sidebar-content-card-mpi-top {
    position: relative;
    background: #ffffff50;
    border-radius: 6px;
    width: 100%;
    font-family: Verdana, sans-serif;
    --max-size: 10;
    --min-size: 8;
    font-size: var(--responsive);
}

.sidebar-content-card-OECD {
    position: relative;
    background: #ffffff50;
    border-radius: 6px;
    width: 100%;
    height: 100%;
    font-family: Verdana, sans-serif;
    --max-size: 9;
    --min-size: 7;
    font-size: var(--responsive);
    color: #000000;
}

.sidebar-title {
    position: relative;
    font-family: Verdana, sans-serif;
    --max-size: 14;
    --min-size: 8;
    font-size: var(--responsive);
    color: #000000;
    font-weight: 700;
}

.sidebar-subtitles {
    position: relative;
    font-family: Verdana, sans-serif;
    --max-size: 9;
    --min-size: 7;
    font-size: var(--responsive);
    font-weight: 900;
    color: #000000;
}

.sidebar-sub-subtitles {
    position: relative;
    font-family: Verdana, sans-serif;
    --max-size: 9;
    --min-size: 7;
    font-size: var(--responsive);
    font-weight: 600;
    color: #000000;
}

.sidebar-toggle {
    position: absolute;
    width: 1.75em;
    height: 1.75em;
    overflow: visible;
    justify-content: center;
    align-items: center;
}

.sidebar-toggle.left {
    right: -2em;
}

.sidebar-toggle:hover {
    color: #0aa1cf;
    cursor: pointer;
}

/* Marker tweaks */
.mapboxgl-popup-close-button {
    display: block;
}

.mapboxgl-popup-anchor-top>.mapboxgl-popup-content {
    margin-top: 0px;
}

.mapboxgl-popup-content {
    font-family: Verdana, sans-serif;
    padding: 1;
    width: 50vmin;
    height: 100%;
    background-color: #ffffff85;
}

.mapboxgl-popup-content div {
    text-align: center;
    font-size: calc(8px + .6vmin);
}

.mapboxgl-popup-content p {
    text-align: center;
    margin-top: 8px;
}

.mapboxgl-popup-content ul {
    columns: 1;
    text-align: left;
}