@import '../node_modules/antd/dist/antd.css';

.App {
  text-align: center;
  height: 1vh;
}

h1 {
  font-family: Verdana, sans-serif;
  font-size: calc(16px + 1.4vmin);
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 26.4px;
}

h2 {
  font-family: Verdana, sans-serif;
  font-size: calc(15px + 1.4vmin);
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 20px;
}

h3 {
  font-family: Verdana, sans-serif;
  font-size: calc(14px + 1.1vmin);
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 20px;
}

h4 {
  font-family: Verdana, sans-serif;
  font-size: calc(13px + .75vmin);
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 20px;
}

h5 {
  font-family: Verdana, sans-serif;
  font-size: calc(11px + 1.1vmin);
  font-style: normal;
  font-variant: normal;
  font-weight: 500;
  line-height: 20px;
}

h6 {
  font-family: Verdana, sans-serif;
  font-size: calc(8px + 1.1vmin);
  font-style: normal;
  font-variant: normal;
  font-weight: 500;
  line-height: normal;
}

p {
  font-family: Verdana, sans-serif;
  font-size: calc(8px + 1.1vmin);
  font-style: normal;
  font-variant: normal;
  font-weight: 500;
  line-height: 20px;
}

blockquote {
  font-family: Verdana, sans-serif;
  font-size: 21px;
  font-style: normal;
  font-variant: normal;
  font-weight: 600;
  line-height: 30px;
}

pre {
  font-family: Verdana, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 18.5714px;
}

.login-wrapper {
  text-align: center;
  background-color: #B2B4B850;
  height: 100%;
  width: 100%;
  position: fixed;
}

.container {
  display: flex;
}

.about-img-container {
  max-width: 750px;
  max-height: fit-content;
}

.logo-container {
  max-width: 200px;
  max-height: 71px;
}

.logo-container-collapsed {
  max-width: 140px;
}

.claims-dash {
  min-width: auto;
  min-height: auto;
}

.faq-equation-container {
  max-width: 500px;
}

.ant-layout {
  background: #e9eefa;
  color: "#424352";
}

.ant-modal-content {
  border-radius: 20px;
  /* background: red; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(255, 255, 255);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: rgb(61, 18, 18);
}

#components-layout-demo-top .logo {
  float: left;
  width: calc(100vh - 100px);
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.price-feed {
  font-size: 18px;
  font-family: Verdana, sans-serif;
  background-color: #e9eefa;
  border-radius: 10px;
}

.scanner {
  position: absolute;
  display: flex;
  top: -100%;
  left: -100%;
  z-index: 1;
}

.create-claim-scanner {
  position: absolute;
  display: flex;
  top: -100%;
  left: -25%;
  z-index: 1;
}

/* front marketplace button */
@media only screen and (max-width: 675px) {
  .front-marketplace-button {
    display: none;
  }
}

.front-marketplace-button {
  display: relative;
  border-radius: 28px;
  padding: 10px 15px;
  font-family: Verdana, sans-serif;
  color: #dddddd;
  background: radial-gradient(circle, rgba(224, 96, 48, 1) 0%, rgba(32, 56, 100, 1) 100%);
  border-width: 0px;
  font-size: calc(9px + 1.3vmin);
  cursor: pointer;
}

.front-marketplace-button:hover {
  color: #ffffff;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.44), 0 17px 50px 0 rgba(0, 0, 0, 0.60);
}

/* Claim Market */
.payment-method-card {
  margin: .75em;
  height: 50px;
  width: 155px;
  cursor: pointer;
  border-radius: 8px;
  background-color: #20386440;
  font-family: Verdana, sans-serif;
  font-size: calc(10px + .65vmin);
}

.payment-method-card:hover {
  background-color: #20386415;
  border-radius: 8px;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

/* Beneficiary table */
.bene-table {
  font-size: calc(8px + 0.95vmin);
}

.bene-table-container {
  padding-bottom: 65%;
  margin-left: 12px;
  margin-right: 12px;
  min-height: '100vh';
  display: flex;
  flex-direction: column;
  font-family: Verdana, sans-serif;
}

/* Create Claim Form */
.create-claim-form {
  font-family: Verdana, sans-serif;
}

/* React Flow Tool */
.node-input {
  color: #fff;
  border: none;
  padding: 15px;
  border-radius: 8px;
  width: 400px;
}

.node-default {
  color: #fff;
  border: none;
  padding: 15px;
  border-radius: 8px;
  width: 325px;
}

.custom-node-info {
  justify-items: left;
}

.node-output {
  color: #fff;
  border: none;
  padding: 15px;
  border-radius: 8px;
  width: 325px;
}

.node-input.node-default.node-output:hover {
  cursor: pointer;
}

.flow-button {
  font-family: Verdana, sans-serif;
  font-size: calc(9px + .65vmin);
  background-color: #20386499;
  border-width: 0px;
  color: #fff;
  border-radius: 8px;
  padding: .75em;
  margin: .55em;
  cursor: pointer;
}

.flow-button:hover {
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
  background-color: #203864;
}

.sidebar-trace-right {
  position: absolute;
  width: auto;
  height: auto;
  top: 4px;
  right: 4px;
  z-index: 1000;
}

.sidebar-trace-left {
  position: absolute;
  width: auto;
  height: auto;
  top: 6px;
  left: 10px;
  z-index: 1000;
}

.sidebar-trace-left-2 {
  position: absolute;
  width: auto;
  height: auto;
  top: 6px;
  left: 65px;
  z-index: 1000;
}

.sidebar-trace-left-3 {
  position: absolute;
  width: auto;
  height: auto;
  top: 6px;
  left: 120px;
  z-index: 1000;
}

.trace-flow-container {
  height: calc(100vh - 85px);
}

/* React Flow - Add Node Form */
.add-Node-Form {
  background-color: #20386499;
  position: absolute;
  left: 10px;
  color: #fff;
  border-radius: 8px;
  padding: 15px;
  font-size: calc(9px + .65vmin);
}

.add-Node-Form:hover {
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
  background-color: #203864;
}

.add-node-form-input {
  padding: 3px;
  color: rgb(61, 18, 18);
  margin-bottom: 4px;
}

.add-node-form-button {
  padding: .75em;
  margin: .75em;
  width: 150px;
  cursor: pointer;
  border-radius: 8px;
  background-color: #495366;
  font-family: Verdana, sans-serif;
  font-size: calc(9px + .65vmin);
  border-width: 0px;
}

.submit-color-form-button {
  padding: .25em;
  margin: .35em;
  width: 35%;
  height: 75%;
  cursor: pointer;
  border-radius: 8px;
  background-color: #778fbe;
  color: white;
  font-family: Verdana, sans-serif;
  font-size: calc(9px + .65vmin);
  border-width: 0px;
}

.submit-color-tier-form-button {
  padding: .25em;
  margin-bottom: .45em;
  margin-left: 6px;
  width: 15%;
  height: 75%;
  cursor: pointer;
  border-radius: 8px;
  background-color: #778fbe;
  color: white;
  font-family: Verdana, sans-serif;
  font-size: calc(9px + .65vmin);
  border-width: 0px;
}

.add-node-form-button:active {
  background-color: #626468;
}

.filter-menu-button-country {
  padding: .45em;
  margin: .45em;
  cursor: pointer;
  border-radius: 8px;
  background-color: #383b3d;
  font-family: Verdana, sans-serif;
  font-size: calc(9px + .65vmin);
  border-width: 0px;
}

.filter-menu-button-prod {
  padding: .45em;
  margin: .45em;
  cursor: pointer;
  border-radius: 8px;
  background-color: #383b3d;
  font-family: Verdana, sans-serif;
  font-size: calc(9px + .65vmin);
  border-width: 0px;
}

.filter-menu-button-va {
  padding: .75em;
  margin: .75em;
  cursor: pointer;
  border-radius: 8px;
  background-color: #383b3d;
  font-family: Verdana, sans-serif;
  font-size: calc(9px + .65vmin);
  border-width: 0px;
}

.filter-menu-button-co {
  padding: .75em;
  margin: .25em;
  width: 200px;
  cursor: pointer;
  border-radius: 8px;
  background-color: #4c506d;
  font-family: Verdana, sans-serif;
  font-size: calc(10px + .65vmin);
  border-width: 0px;
}

.filter-menu-button-ni {
  padding: .75em;
  margin: .25em;
  width: 200px;
  cursor: pointer;
  border-radius: 8px;
  background-color: #8f8e8e;
  font-family: Verdana, sans-serif;
  font-size: calc(10px + .65vmin);
  border-width: 0px;
}

.filter-menu-button-li {
  padding: .75em;
  margin: .25em;
  margin-bottom: .50em;
  width: 200px;
  cursor: pointer;
  border-radius: 8px;
  background-color: #afb1b1;
  font-family: Verdana, sans-serif;
  font-size: calc(10px + .65vmin);
  border-width: 0px;
}

.filter-menu-button-country.pressed {
  background-color: #5c5f61;
  transform: translateY(2px);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

.filter-menu-button-prod.pressed {
  background-color: #5c5f61;
  transform: translateY(2px);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

.filter-menu-button-co.pressed {
  background-color: #727071;
  transform: translateY(2px);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

.filter-menu-button-ni.pressed {
  background-color: #727071;
  transform: translateY(2px);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

.filter-menu-button-li.pressed {
  background-color: #727071;
  transform: translateY(2px);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

.filter-menu-button-va.pressed {
  background-color: #dad8d9;
  transform: translateY(2px);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

.filter-button-container {
  background: #2d335355;
  border-radius: 8px;
  margin-bottom: 4px;
}

#add-button {
  position: relative;
  left: 0px;
}

.notdisplayed {
  display: none;
}

.displayed {
  display: flex;
  height: 20px;
  margin: auto;
}

.filter-utils {
  background-color: #20386499;
  position: absolute;
  left: -45px;
  color: #fff;
  border-radius: 8px;
  padding: 12px;
  font-size: calc(9px + .65vmin);
}

#edgebutton {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 25%;
  top: 40%;
  padding-left: 12px;
  margin: none;
  background: #bebaba60;
  border: 0px dashed #fff;
  cursor: pointer;
  border-radius: 50%;
  font-size: 14px;
  font-weight: 400;
}

#edgebutton:hover {
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
}

.edge-label {
  position: absolute;
  left: 5%;
  top: 25%;
  margin: none;
  background-color: #D3D2E5;
  padding: 10px;
  font-size: 18px;
  border-radius: 8px;
}

.edgebutton-foreignobject body {
  background: transparent;
  width: 100px;
  height: 100px;
}

.filter-form {
  background-color: #20386499;
  position: absolute;
  left: 10px;
  color: #fff;
  border-radius: 8px;
  padding: 12px;
  font-size: calc(9px + .65vmin);
}

path.react-flow__edge-path {
  stroke-width: 4;
}

/* Color Options form */
.color-utils-form {
  background-color: #20386499;
  position: absolute;
  left: -100px;
  color: #fff;
  border-radius: 8px;
  padding: 12px;
  font-size: calc(9px + .65vmin);
}

.tier-colors-container {
  width: 225px;
  background: #2d335355;
  border-radius: 8px;
  padding-bottom: 8px;
  margin-bottom: 12px;
}

.tier-colors-form-input {
  padding: 3px;
  color: rgb(61, 18, 18);
  margin-bottom: 4px;
  margin-right: 6px;
  margin-left: 6px;
  width: 25%;
}

.rotated-icon-90 {
  -webkit-transform: rotate(270deg);
  font-size: 14px
}

/* Download Image */
#trace-flow-container .react-flow {
  background: #ffffff;
}

#trace-flow-container .react-flow__node-custom .react-flow__handle-right {
  transform: none;
}

#trace-flow-container.wrapper {
  position: relative;
  height: calc(100vh);
}

/* connect button */
.connect-button {
  border-width: 1px;
  border-color: #cecece;
  margin-top: 0px;
  border-radius: 8px;
  font-weight: 100;
}

.connect-button:hover {
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
}

.user-profile-btn {
  border-radius: 10px;
  color: #dddddd;
  background: radial-gradient(circle, rgba(224, 96, 48, 1) 18%, rgba(43, 74, 131, 1) 100%);
  border-width: 0px;
  padding-top: 4px;
  padding-bottom: 4px;
  text-overflow: ellipsis;
}

.user-profile-btn:hover {
  color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 6px 2px rgb(201, 201, 201);
  cursor: pointer;
}

.bottom-btn-bar {
  position: fixed;
  bottom: 25px;
}

/* Logout Button */
.logout-btn-profile {
  text-align: center;
  background: #ff787599;
  border-color: #B2B4B850;
  color: black;
}

.logout-btn-profile:hover {
  background: #ff7875;
  border-color: #B2B4B8;
  color: black;
  font-weight: 700;
  box-shadow: 0 0 12px 4px rgb(201, 201, 201);
}

/* MapBox profile btn */
.map-btn-profile {
  text-align: center;
  background: #6093ff99;
  border-color: #B2B4B850;
  color: black;
}

.map-btn-profile:hover {
  background: #6093ff;
  border-color: #B2B4B8;
  color: black;
  font-weight: 700;
  box-shadow: 0 0 12px 4px rgb(201, 201, 201);
}

/* Antd Menu and SubMenu Formatting */

/* main menu */
.ant-menu>.ant-menu-submenu {
  font-family: Verdana, sans-serif;
  font-size: 14px;
  font-weight: bold;
  background-color: #203864;
}

/* sub menu */
.ant-menu-item-group,
.ant-menu-item {
  font-family: Verdana, sans-serif;
  font-size: 14px;
  font-weight: bold;
  background-color: #203864;
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background: #203864;
}

/* menu item hover */
.ant-menu>.ant-menu-item:hover,
.ant-menu>.ant-menu-submenu:hover,
.ant-menu>.ant-menu-item-active,
.ant-menu>.ant-menu-submenu-active,
.ant-menu>.ant-menu-item-open,
.ant-menu>.ant-menu-submenu-open,
.ant-menu>.ant-menu-item-selected,
.ant-menu>.ant-menu-submenu-selected {
  color: rgb(255, 255, 255);
  background-color: #203864;
}



/* SBG Dashboard */
.mine-status-table {
  margin-top: 20px;
}

.ant-table-thead>tr>th {
  font-weight: bold;
  color: #333;
  background-color: #f7f7f7;
  border: 1px solid #ebedf0;
}

.ant-table-tbody>tr>td {
  border: 1px solid #ebedf0;
}

.ant-table-tbody>tr:hover>td {
  background-color: #f5f5f5;
}

.ant-pagination {
  margin-top: 20px;
  text-align: right;
}

.ant-pagination-item-active>a {
  background-color: #1890ff;
  border-color: #1890ff;
}

.ant-pagination-item-active>a:hover {
  background-color: #1890ff;
  border-color: #1890ff;
}

.ant-pagination-item>a:hover {
  border-color: #1890ff;
}

.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link,
.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link {
  border-color: #1890ff;
}

.ant-pagination-prev:hover .ant-pagination-item-link-icon,
.ant-pagination-next:hover .ant-pagination-item-link-icon,
.ant-pagination-prev:focus .ant-pagination-item-link-icon,
.ant-pagination-next:focus .ant-pagination-item-link-icon {
  color: #1890ff;
}