@import './../../node_modules/antd/dist/antd.css';

.container {
    display: flex;
    flex-direction: column;
    margin: 0%;
    min-height: 100vh;
    font-family: Verdana, sans-serif;
}

.user-info {
    font-size: calc(6px + .9vmin);
    text-overflow: clip;
    text-align: left;
    padding-top: 4px;
}

.user-info-title {
    font-weight: bold;
    margin: 0px;
}

.account-info {
    margin-left: 12px;
    font-weight: bold;
}

.account-status {
    font-size: calc(8px + .9vmin);
    text-overflow: clip;
}

.usdc-account-btn {
    font-size: calc(6px + .9vmin);
    margin: 6px;
}

.usdc-alert {
    border-radius: 8px;
    padding: 6px;
    margin-bottom: 6px;
}

.usdc-alert:hover {
    box-shadow: 5px 5px 5px rgb(197, 197, 197);
    font-weight: 700;
}

.ant-table {
    border-radius: 24px;
    font-size: calc(6px + .9vmin);
}

.account-kyc {
    font-size: calc(6px + .9vmin);
    margin-left: 12px;
    color: #f4c63c;
}

.account-kyc:hover {
    font-weight: bold;
}

.signup-bank-button {
    background-color: rgba(31, 35, 102, 0.15);
    border-color: rgba(31, 35, 102, 0.05);
    margin: 12px;
    border-radius: 16px;
}

.signup-bank-button:hover {
    background-color: rgb(31, 35, 102, 0.55);
    border-color: rgba(31, 35, 102, 0.25);
    box-shadow: 5px 5px 5px rgb(31, 35, 102, 0.45);
    border-radius: 16px;
}

.bank-btn-text {
    font-size: calc(9px + 1.05vmin);
}